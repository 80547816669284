var jquery = require("jquery");
window.$ = window.jQuery = jquery;

$(document).ready(function () {
    window.onscroll = function () {
        const num = 80;
        $(window).bind('scroll', function () {
            if ($(window).scrollTop() > num) {
                $('#mobile-menu').addClass('sticky-mobile');
            } else {
                $('#mobile-menu').removeClass('sticky-mobile');
            }
        });
    };
    $("#mobile-menu-close").on('click', function () {
        $("#mobile-menu").hide();
        $(this).hide();
        $("#mobile-menu-button").show();
        //$('.mobile-navbar').show();
    });

    $("#mobile-menu-button").on('click', function () {
        $("#mobile-menu").show();
        $(this).hide();
        $("#mobile-menu-close").show();
        //$('.mobile-navbar').hide();
    });
});

function populate(data, pagination){
    console.log(data)

    console.log(pagination)
    //Replace headline
    //Replace image
    //Replace number
}